.head_row {
  color: #9ca3af;
  font-size: 12px;
  user-select: none;
}

.cell {
  font-size: 14px;
}

.day {
  height: 36px;
  width: 36px;
}

.day_today {
  font-weight: 600;
  position: relative;
}

.day_today:after {
  content: '';
  background: #3c4858;
  height: 2px;
  position: absolute;
  bottom: 5px;
  left: 20%;
  width: 60%;
}

.day_disabled {
  color: #d1d5db;
  text-decoration: line-through;
}

.day_outside {
  color: #9ca3af;
}

.button {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}